<template>
  <!-- 内推活动计划 -->
  <div>
    <div class="plan-banner">
      <div class="banner-title">
        <div class="main-title">北美内推计划</div>
        <div>
          <div class="sub-title1">Intern &amp; New Grad &amp; 在职跳槽</div>
          <div class="sub-title2">不忘初衷，免费内推</div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="page-title">
        “北美内推计划”免费内推 Intern &amp; New Grad &amp; 在职跳槽
      </div>
      <!--      <div class="create-time">2021.09.01 12:12:00</div>-->
      <div class="section-title">【我的初衷】</div>
      <div class="section-content">
        <p>大家好，我是Edward。</p>
        <p>
          做这个网站已经三年有余，其实总是想为我们留学生群体做更多的工作，但无论是中美的关系，还是疫情全球形式的影响，都让我们的留学生涯雪上加霜。尤其是政策影响，让我们很多留学生在已经拿到
          offer的情况下，无法来到美国。其实在这种全球大势的情况下，有时候感觉很深深的无奈，我总想去凭着自己的努力去改变什么，想着自己能去做些什么，但个人的力量太渺小，根本无力去改变这样的局面，
          我也只能想在自己的领域，能尽一份力尽一份力。
        </p>
        <p>
          想来想去，好像自己也只能做些力所能及的助力。CSON建立三年，中间波折困难真的很多，我不知道这个网站能做到什么时候，疫情以来，
          已经亏损一年半多，但今年河南发水，有些企业也是一样处于亏损仍然捐赠千万，其实给我很大触动。那时候我就想，即使有一天不行了，但我也想把我所有的想法努力都尝试一次，至少求个无怨无悔。
        </p>
        <p>
          所以打算认真做这件事情，想试试，试试能不能把"内推"这件事情做好。内推是我在留学的时候就想尝试的一件事情，当时就想为什么海外的中国人这么多，大家不能像三哥三姐一样那么团结互助，所以
          我想试试能不能凭一己之力做好这件事情，即使失败了我也想尝试一下，至少我要努力一次。
        </p>
        <p>
          其实之前很多国人做过内推平台，但最后都失败了。我认为最大的原因就是内推无效化太多，我理解留学生刚来美国着急，着急去找实习全职，但很多人题都没刷几道就急着去找人推，最后的结果就是面一个挂一个。
          很多国人同胞一开始也是抱着帮助同胞的想法去帮忙推自己的公司，但最后100个人有的是没一个过的，失望大于期望，而且这样对自己在公司的影响也是不好的，所以慢慢就不愿意推了。
        </p>
        <p>
          当年我留学的时候，刷题过千，很多人是看着我的视频拿到Google，facebook的offer的，感觉自己空有屠龙之术，连个表现的机会都没有。所以我想，给那些有实力有水平的同学们，一次机会，刷题不够
          水平还欠缺的同学，好好努力提升水平，我们随时欢迎。同时，也给那些已经上岸的同胞们一些信心，不是推一个不成一个，我们做的事情，无论是成功还是失败，都是有意义的。
        </p>
        <p>
          算了一下，三年多，联系了一些想推的曾经的学生，朋友，合作伙伴，大概能推40+公司，主流的Google，facebook，Amazon，Microsoft，Uber，北美字节，VMware，PayPal，eBay等等这些公司，
          我都有资源人脉，这还是在我没有扩大范围问其他愿意推的人的情况下。所以有这么多的资源，我真的想试试，为我们的同胞做一些事情，看看，到底行不行。
          如果有在公司上班的兄弟姐妹想加入我们的内推Family，随时和我联系。
        </p>
        <p>
          希望大家能够支持一下，多告诉一些身边的人，如果是学生，多推荐一下，如果是上班的，联系我，我们一起做起来！
        </p>
        <!--        <p>-->
        <!--          「任务二」自参与活动之日起，5日内，需在个人打卡页面，发表3条个人动态，与大家分享个人学习经验。-->
        <!--        </p>-->
      </div>
      <div class="section-title">【内推流程】</div>
      <div class="section-content">
        <!--        <p>-->
        <!--          1、添加微信号 - 奖励-->
        <!--          <span style="color: rgba(250, 100, 0, 1)"-->
        <!--            >「LeetCode 1-400题讲解」</span-->
        <!--          >课，<span style="color: rgba(250, 100, 0, 1)">15天</span>免费学习奖励-->
        <!--        </p>-->
        <p>
          1、添加微信 - 自我介绍 + 转发，简单介绍一下自己，找实习全职等基本情况
        </p>
        <p>
          2、上传资料 -
          Resume，Email，想推公司，不推公司（已经拿到面试或已推公司）等
        </p>
        <p>3、内推 - 联系内推人，如果有人愿意推，会发邮件等方式联系学生</p>
      </div>
      <div class="section-title">【内推细节】</div>
      <div class="section-content">
        <p>
          1、内推公司数量，取决于自己的简历和刷题水平实力情况，优秀的肯定会得到很多内推人青睐，我们尽量去保证每个学生得到公司的内推机会，但内推与否是取决于内推人，如果优秀，<span
            style="color: rgba(250, 100, 0, 1)"
          >
            「10家」</span
          >起步
        </p>
        <p>
          2、留学生实力水平，我们希望推的人是有成功率的，水平不行推了也是挂，就没有意义了。所以会有一定的门槛
        </p>
        <p>
          3、全程自愿原则，不会强迫也不会泄露个人任何信息，网站成立三年以来没有在信息安全出国问题，所有信息均在阿里云存储，请大家放心
        </p>
        <p>
          4、因公司数量和内推人数量有限，请大家积极报名，<span
            style="color: rgba(250, 100, 0, 1)"
            >先到先得</span
          >
        </p>
        <div class="section-title">【内推人】</div>
        <div class="section-content">
          <p>
            如果有公司的在职同胞想加入内推计划推学生，尽一份力，随时联系我，不要有自己公司不是大厂等毫无意义的担心，只要有这个心，就是好样的
          </p>
          <p>个人微信号：cspiration</p>
        </div>
        <div class="section-title">【活动时间】</div>
        <div class="section-content">
          <p>2021.9.13 ~ 2021.10.13</p>
        </div>
        <p style="color: rgba(255, 59, 48, 1)">
          我在此郑重承诺，活动期间，内推计划全程免费
        </p>
      </div>

      <div class="interviewButton">
        <div class="ibContainer" @click="getInterview">立即获得内推</div>
      </div>
      <el-dialog
        :title="!showQrcode ? '填写内推信息' : ''"
        :width="!showQrcode ? '800px' : '300px'"
        :visible.sync="interviewVisible"
        append-to-body
        custom-class="referDialog"
        :top="!showQrcode ? '2vh' : '15vh'"
        :show-close="!showQrcode"
      >
        <el-form
          :model="form"
          ref="ruleForm"
          label-width="150px"
          v-if="!showQrcode"
          :rules="rules"
        >
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" placeholder="请填写邮箱"></el-input>
          </el-form-item>

          <el-form-item label="求职目标" prop="basics.target">
            <el-radio-group
              v-model="form.basics.target"
              @change="$forceUpdate()"
            >
              <el-radio :label="0">New Grad</el-radio>
              <el-radio :label="1">Internship</el-radio>
              <el-radio :label="2">合同工</el-radio>
              <el-radio :label="3">跳槽</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="面试岗位" prop="basics.post">
                <el-select
                  v-model="form.basics.post"
                  placeholder="请选择岗位"
                  style="margin-bottom: 0"
                  @change="$forceUpdate()"
                >
                  <el-option label="码农SDE" :value="0"></el-option>
                  <el-option label="前端FrontEndEng" :value="1"></el-option>
                  <el-option label="移动端MobileEng" :value="2"></el-option>
                  <el-option
                    label="机器学习MachineLearningEng"
                    :value="3"
                  ></el-option>
                  <el-option label="数据科学DataScience" :value="4"></el-option>
                  <el-option label="产品PM" :value="5"></el-option>
                  <el-option label="管理Manager" :value="6"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所学专业" prop="basics.specialty">
                <el-select
                  v-model="form.basics.specialty"
                  placeholder="请选择所学专业"
                  @change="$forceUpdate()"
                >
                  <el-option label="CS" :value="0"></el-option>
                  <el-option label="MIS" :value="1"></el-option>
                  <el-option label="EE" :value="2"></el-option>
                  <el-option label="ME" :value="3"></el-option>
                  <el-option label="BME" :value="4"></el-option>
                  <el-option label="ECON" :value="5"></el-option>
                  <el-option label="MFE" :value="6"></el-option>
                  <el-option label="ACCOUNTING" :value="7"></el-option>
                  <el-option label="其它" :value="8"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="工作经验" prop="basics.workExperience">
                <el-select
                  v-model="form.basics.workExperience"
                  placeholder="工作经验"
                  @change="$forceUpdate()"
                >
                  <el-option
                    label="fresh grad 无实习或全职"
                    :value="0"
                  ></el-option>
                  <el-option label="3个月实习或全职" :value="1"></el-option>
                  <el-option label="3个月-1年" :value="2"></el-option>
                  <el-option label="1-3年" :value="3"></el-option>
                  <el-option label="3-5年" :value="4"></el-option>
                  <el-option label="5-10年" :value="5"></el-option>
                  <el-option label="11-15年" :value="6"></el-option>
                  <el-option label="15年以上" :value="7"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="学历信息" prop="basics.highestEducation">
                <el-select
                  v-model="form.basics.highestEducation"
                  placeholder="目前最高学历"
                  @change="$forceUpdate()"
                >
                  <el-option label="本科" :value="0"></el-option>
                  <el-option label="硕士" :value="1"></el-option>
                  <el-option label="博士" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="毕业院校" prop="basics.graduatedFrom">
            <el-autocomplete
              v-model="form.basics.graduatedFrom"
              :fetch-suggestions="querySchoolSearch"
              :trigger-on-focus="false"
              placeholder="请选择学校（国内大学请输入中文，国外输入英文）"
              :popper-append-to-body="false"
              @input="
                (val) => {
                  $forceUpdate();
                  searchSchoolLodash(val);
                }
              "
              @select="selectSchool"
              style="width: 100%"
            >
              <template slot-scope="{ item }">
                {{ item.name }}
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="简历" prop="resume">
            <el-upload
              ref="upload"
              :on-success="handlePDFSuccess"
              :limit="1"
              :before-upload="beforeUpload"
              :on-remove="removePDFFile"
              :action="uploadUrl"
              class="upload-demo"
              accept=".pdf,.PDF"
              style="display: inline-block; width: 100%"
              :data="{ isPublic: false }"
              :headers="{ 'X-Access-Token': token }"
            >
              <el-button icon="el-icon-paperclip" size="small" type="primary"
                >选择简历
              </el-button>
              <div class="el-upload__tip" slot="tip">
                为了方便内推，文件名必须和简历里的名字一致
              </div>
            </el-upload>
          </el-form-item>
          <el-row>
            <el-col :span="14">
              <el-form-item label="LeetCode刷题截图" prop="lcPrintScreen">
                <el-upload
                  ref="upload"
                  :on-success="handleLCSuccess"
                  :limit="3"
                  :on-remove="removeLCFile"
                  :action="uploadUrl"
                  class="upload-demo"
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                  style="display: inline-block; margin-right: 12px"
                  :data="{ isPublic: false }"
                  :headers="{ 'X-Access-Token': token }"
                  :before-upload="beforeUpload"
                >
                  <el-button
                    icon="el-icon-paperclip"
                    size="small"
                    type="primary"
                    slot="trigger"
                    >选择图片
                  </el-button>
                  <span style="vertical-align: top; margin-left: 12px"
                    >例图：</span
                  >

                  <el-image
                    style="width: 100px; vertical-align: top; cursor: pointer"
                    :src="require('@/assets/refer/leetcode.png')"
                    @click="showViewer = true"
                  />
                  <el-image-viewer
                    v-if="showViewer"
                    :z-index="9999"
                    :url-list="[require('@/assets/refer/leetcode.png')]"
                    :on-close="closeViewer"
                  />
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="刷题量" prop="questions">
                <el-input
                  v-model="form.questions"
                  type="number"
                  placeholder="请填写刷题量"
                  style="width: 150px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="想推公司" prop="wantTo">
            <el-select
              v-model="form.wantTo"
              multiple
              filterable
              placeholder="请选择"
              remote
              :remote-method="searchLodash"
              style="width: 100%"
              :loading="selectLoading"
              @change="$forceUpdate()"
              :multiple-limit="8"
            >
              <el-option
                v-for="item in companys"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="不想推公司">
            <el-select
              v-model="form.doNotWantTo"
              multiple
              filterable
              placeholder="已有公司面试，或者已找其他内推人，无需再推"
              remote
              :remote-method="searchLodash"
              :loading="selectLoading"
              style="width: 100%"
              @change="$forceUpdate()"
              :multiple-limit="8"
            >
              <el-option
                v-for="item in companys"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三人称简介" prop="selfIntroduction">
            <el-input
              v-model="form.selfIntroduction"
              type="textarea"
              placeholder="请填写第三人称简介"
              @input="$forceUpdate()"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          style="width: 100%; text-align: center; position: relative"
          v-if="showQrcode"
        >
          <img
            src="@/assets/company/qrcode.png"
            alt=""
            style="width: 150px; margin: 0 auto"
          />
          <div style="margin-top: 12px; padding-bottom: 24px">
            请添加微信
            <br />
            转发海报即可获得内推
          </div>
          <img
            class="close"
            style="
              position: absolute;
              bottom: -50px;
              transform: translateX(-50%);
              cursor: pointer;
            "
            src="@/assets/img/close-icon.png"
            @click="interviewVisible = false"
          />
        </div>

        <div slot="footer" v-if="!showQrcode">
          <el-button @click="confirmRefer" size="mini" type="primary"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapState } from "vuex";
import {
  searchCompanys,
  getInternalRecommendation,
  confirmRefer,
  getSchools,
} from "@/service/company";
import _ from "lodash";
export default {
  name: "interviewPlan",
  components: {
    ElImageViewer,
  },
  data() {
    var validateResume = (rule, value, callback) => {
      if (
        this.form.resume == "" ||
        !this.form.resume ||
        this.form.resumeAll == "" ||
        !this.form.resumeAll
      ) {
        callback(new Error("请上传简历!"));
      } else {
        callback();
      }
    };
    var validateLeetCode = (rule, value, callback) => {
      if (
        this.form.lcPrintScreen == "" ||
        !this.form.lcPrintScreen ||
        this.form.lcPrintScreenAll == "" ||
        !this.form.lcPrintScreenAll
      ) {
        callback(new Error("请上传LeetCode截图!"));
      } else {
        callback();
      }
    };
    return {
      interviewVisible: false,
      form: {
        email: "",
        basics: {
          target: "",
          post: "",
          specialty: "",
          workExperience: "",
          highestEducation: "",
          graduatedFrom: "",
        },
        questions: "",
        wantTo: [],
        doNotWantTo: [],
        selfIntroduction: "",
      },
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      showViewer: false,
      donotwantCompanys: [],
      wantCompanys: [],
      companys: [],
      hotCompanys: [],
      status: 0,
      showQrcode: false,
      selectLoading: false,
      rules: {
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        questions: [
          { required: true, message: "请输入刷题量", trigger: "blur" },
        ],
        resume: [
          {
            required: true,
            validator: validateResume,
            trigger: "change",
          },
        ],
        lcPrintScreen: [
          {
            required: true,
            validator: validateLeetCode,
            trigger: "change",
          },
        ],
        wantTo: [
          {
            required: true,
            message: "请选择想推公司",
            trigger: "change",
          },
        ],
        selfIntroduction: [
          { required: true, message: "请输入第三人称简介", trigger: "blur" },
        ],
        "basics.target": [
          {
            required: true,
            message: "请选择求职目标",
            trigger: "blur",
          },
        ],
        "basics.post": [
          {
            required: true,
            message: "请选择面试岗位",
            trigger: "blur",
          },
        ],
        "basics.specialty": [
          {
            required: true,
            message: "请选择所学专业",
            trigger: "blur",
          },
        ],
        "basics.workExperience": [
          {
            required: true,
            message: "请选择工作经验",
            trigger: "blur",
          },
        ],
        "basics.highestEducation": [
          {
            required: true,
            message: "请选择最高学历",
            trigger: "blur",
          },
        ],
        "basics.graduatedFrom": [
          {
            required: true,
            message: "请输入毕业院校",
            trigger: "blur",
          },
        ],
      },
      schools: [],
    };
  },
  methods: {
    getInterview() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.getInternalRecommendation();
    },
    handlePDFSuccess(res, file) {
      this.form.resume = res.result.objectKey;
      this.form.resumeAll = res.result.accessibleUrl;

      if (file.name.indexOf(".pdf") != -1) {
        this.form.name = file.name.split(".pdf")[0];
      } else if (file.name.indexOf(".PDF") != -1) {
        this.form.name = file.name.split(".PDF")[0];
      } else {
        this.form.name = "";
      }
    },
    removePDFFile() {
      this.form.resume = "";
      this.form.resumeAll = "";
    },
    handleLCSuccess(res) {
      this.form.lcPrintScreen = res.result.objectKey;
      this.form.lcPrintScreenAll = res.result.accessibleUrl;
    },
    removeLCFile() {
      this.form.lcPrintScreen = "";
      this.form.lcPrintScreenAll = "";
    },
    closeViewer() {
      this.showViewer = false;
    },
    searchLodash: _.debounce(function (val) {
      this.selectLoading = true;
      this.searchCompanys(val);
    }, 1000),
    searchCompanys(val) {
      searchCompanys(val).then((res) => {
        if (res.success) {
          this.selectLoading = false;
          this.companys = res.result;
        }
      });
    },
    getInternalRecommendation() {
      getInternalRecommendation().then((res) => {
        if (res.success) {
          this.status = res.result.status.value;
          if (this.status == 0 || this.status == 1) {
            this.interviewVisible = true;
            if (this.status == 0) {
              this.showQrcode = false;
              this.form.email = res.result.email ? res.result.email : "";
              this.form.id = res.result.id ? res.result.id : "";
              this.form.questions = res.result.questions
                ? res.result.questions
                : "";
              this.form.selfIntroduction = res.result.selfIntroduction
                ? res.result.selfIntroduction
                : "";
              this.form.basics = res.result.basics
                ? res.result.basics
                : {
                    target: "",
                    post: "",
                    specialty: "",
                    workExperience: "",
                    highestEducation: "",
                    graduatedFrom: "",
                  };
              this.form.wantTo = [];
              this.form.doNotWantTo = [];

              this.$forceUpdate();
            } else {
              this.showQrcode = true;
            }
          } else if (this.status == 2) {
            this.$message.info("正在内推中，请耐心等待");
          } else if (this.status == 3) {
            this.$message.success("内推成功！");
          }
        }
      });
    },
    confirmRefer() {
      console.log(this.$refs.ruleForm);
      console.log(this.form);
      this.$refs["ruleForm"].validate((valid) => {
        console.log(valid);
        if (valid) {
          confirmRefer(this.form).then((res) => {
            if (res.success) {
              this.showQrcode = true;
              this.$message.success("提交成功！");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isLt10M;
    },
    querySchoolSearch(queryString, cb) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.schools);
      }, 1000);
    },
    searchSchools(val) {
      getSchools(val).then((res) => {
        if (res.success) {
          this.schools = res.result;
        }
      });
    },
    selectSchool(option) {
      this.form.basics.graduatedFrom = option.name;
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    searchSchoolLodash: _.debounce(function (val) {
      this.searchSchools(val);
    }, 1000),
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    form: {
      handler(val) {
        this.$forceUpdate();
      },
      deep: true,
    },
    "form.basics": {
      handler(val) {
        this.$forceUpdate();
      },
    },
  },
};
</script>
<style scoped>
.plan-banner {
  position: relative;
  background: url(../../assets/company/interviewPlan.png) no-repeat center top;
  height: 300px;
}
.plan-banner img {
  width: 100%;
  vertical-align: top;
}
.banner-title {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
}
.main-title {
  font-size: 28px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  letter-spacing: 2px;
  margin-bottom: 30px;
}
.sub-title1,
.sub-title2 {
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  margin-bottom: 10px;
}
.container {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.page-title {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
}
.create-time {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-top: 16px;
}
.section-title {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #fa6400;
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-decoration: underline;
  text-decoration-color: rgba(250, 100, 0, 0.2);
}
.section-content p {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(52, 73, 94, 0.7);
  line-height: 18px;
  margin-bottom: 16px;
  line-height: 24px;
}
.interviewButton {
  text-align: center;
  margin-top: 100px;
}
.ibContainer {
  background-image: linear-gradient(to right, #fd1d1d, #fcb045);
  line-height: 44px;
  width: 300px;
  margin: 0 auto;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
}
.referDialog {
  z-index: 99 !important;
}
::v-deep .el-dialog__wrapper {
  z-index: 98 !important;
}
::v-deep .el-dialog__body {
  padding: 12px 20px;
}
.el-upload__tip {
  display: inline;
  margin-left: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>